import React from "react";
import theme from "theme";
import { Theme, Text, Section, Icon, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { BsMap, BsEnvelope, BsClock } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Gear & Grind Rentals
			</title>
			<meta name={"description"} content={"Where Adventure Begins on Two Wheels"} />
			<meta property={"og:title"} content={"Gear & Grind Rentals"} />
			<meta property={"og:description"} content={"Where Adventure Begins on Two Wheels"} />
			<meta property={"og:image"} content={"https://rovenabet.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://rovenabet.com/img/favicon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://rovenabet.com/img/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://rovenabet.com/img/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://rovenabet.com/img/favicon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://rovenabet.com/img/favicon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://rovenabet.com/img/favicon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Get in Touch
			</Text>
			<Text
				as="p"
				font="--base"
				margin="20px 0 20px 0"
				text-align="center"
				max-width="940px"
			>
				Ready to hit the road? We're here to make your motorbike rental experience seamless and memorable. Reach out to us for bookings, inquiries, or just to chat about your next adventure.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" background="linear-gradient(180deg,rgba(222, 53, 11, 0.7) 0%,rgba(222, 53, 11, 0.99) 100%) 0 0 no-repeat" quarkly-title="Contacts-7">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 0 0px"
				sm-grid-template-columns="1fr"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsMap}
						size="54px"
						margin="0px 0px 20px 0px"
						color="#dae0e5"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Location
					</Text>
					<Text
						margin="0px 0px 0 0px"
						color="#dae0e5"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						73 Broughton St, Old Guildford NSW 2161, Australia
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsEnvelope}
						size="54px"
						margin="0px 0px 20px 0px"
						color="#dae0e5"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Contacts:
					</Text>
					<Link
						href="tel:+61 414 310 130"
						color="#dae0e5"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						display="block"
						text-align="center"
						margin="0px 0px 8px 0px"
					>
						+61 414 310 130
					</Link>
					<Link
						href="mailto:contact-us@rovenabet.com"
						color="#dae0e5"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						display="block"
						margin="0px 0px 0 0px"
						text-align="center"
					>
						contact-us@rovenabet.com
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsClock}
						size="54px"
						margin="0px 0px 20px 0px"
						color="#dae0e5"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Working Hours
					</Text>
					<Text
						margin="0px 0px 15px 0px"
						color="#dae0e5"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
					>
						10 a.m. - 8 p.m. Daily
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});